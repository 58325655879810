import React from "react";
import PropTypes from "prop-types";
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';


import * as styles from './draftJS.module.scss';

const styleMap = {
    'STRIKETHROUGH': {
        'color': '#3c3856'
    }
}

const options = {
    blockStyleFn: (block) => {
        return {
            attributes: {className: getBlockStyle(block)}
        }
    },
    inlineStyles: {
        BOLD: {
            attributes: {
                className: styles.bold
            }
        },
        ITALIC: {
            attributes: {
                className: styles.italic
            }
        },
        UNDERLINE: {
            attributes: {
                className: styles.underline
            }
        },
        STRIKETHROUGH: {
            attributes: {
                className: styles.purple,
            }
        }
    } 
};

export class DraftJS extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            editorState: EditorState.createEmpty(),
      
        };   

        this.onChange = this.onChange.bind(this);
        this.setDomEditorRef = this.setDomEditorRef.bind(this);
        this.focus = this.focus.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);



    }  


    onChange(newEditorState) {
        const contentState = newEditorState.getCurrentContent();
        const content = convertToRaw(contentState);
        const contentJSON = JSON.stringify(content);
        const HTML = stateToHTML(contentState, options);
        this.props.getEditorContent(contentJSON, HTML);
        this.setState({editorState: newEditorState});

    } 

    setDomEditorRef(ref) {
        this.domEditor = ref;
    }

    focus() {
        this.domEditor.focus();
    }

    _toggleBlockType(blockType) {
          this.onChange(
            RichUtils.toggleBlockType(
              this.state.editorState,
              blockType
            )
          );
    }

    _toggleInlineStyle(inlineStyle) {
          this.onChange(
            RichUtils.toggleInlineStyle(
              this.state.editorState,
              inlineStyle
            )
          );
    }




    componentDidUpdate(prevProps, prevState) {
        if (!this.props.newEvent && !prevProps.recEditorContent && this.props.recEditorContent) {
            try {
                const contentJS = JSON.parse(this.props.recEditorContent);
                const editorState = EditorState.createWithContent(convertFromRaw(contentJS));
                this.setState({editorState: EditorState.moveFocusToEnd(editorState)}); 
            } catch(e) {
                console.error(e);

            }

        }
    }
 




    render() {
        return (
            <div ref={this.props.innerRef}>
                
                <div className={styles.editorContainer} onClick={this.focus}>
                    <div className={styles.formatContainer}>
                        <InlineStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                        <BlockStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleBlockType}
                        />
                        
                    </div>
                    <div className={styles.editor}>
                        <Editor 
                        customStyleMap={styleMap}
                        blockStyleFn={getBlockStyle}
                        editorState={this.state.editorState} 
                        onChange={this.onChange}
                        ref={this.setDomEditorRef}
                         />

                    </div> 
                </div>

            </div>
        );
    }
}


function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return styles.blockquote;
      case 'small-text': return styles.smallText;
      case 'medium-text': return styles.mediumText;
      case 'large-text': return styles.largeText;
      case 'unordered-list-item': return styles.lista;
      default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
    render() {

      return (
        <span className={this.props.active ? `${styles.formatButton} ${this.props.className} ${styles.activeButton}` : `${styles.formatButton} ${this.props.className}`} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
}

const BLOCK_TYPES = [
    {label: 'Nagy', style: 'large-text'},
    {label: 'Közepes', style: 'medium-text'},
    {label: 'Kicsi', style: 'small-text'},
    {label: 'Idézet', style: 'blockquote'},
    {label: 'Lista', style: 'unordered-list-item'},
 
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return (
      <div className={styles.formatControls}>
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};

const INLINE_STYLES = [
    {label: 'B', style: 'BOLD', className: styles.bold},
    {label: 'I', style: 'ITALIC', className: styles.italic},
    {label: 'U', style: 'UNDERLINE', className: styles.underline},
    {label: 'Színes', style: 'STRIKETHROUGH', className: ""},
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className={styles.formatControls}>
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            className={type.className}
          />
        )}
      </div>
    );
};


DraftJS.propTypes = {
    token: PropTypes.string.isRequired,
    getEditorContent: PropTypes.func.isRequired,
    recEditorContent: PropTypes.string.isRequired,
   

};

