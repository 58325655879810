
import React from "react";
import PropTypes from "prop-types";
import {devURL} from '../../../config/pages';
import {DraftJS} from './draftJS';
import {titleRow, row, delCell, table, linkCell} from './table';
import {formatCreatedDisplayDate} from '../../../common/contentAux';
import {postToDB} from './dbCalls/postToDB';
import {postFileToServer} from './dbCalls/postFileToServer';
import * as styles from './eventEditor.module.scss';
import {tableTitle as tsTabTitle} from './table.module.scss';

const filesHeading = titleRow(["Név", ""]);
const filesEntries = (files) => files.map((file, i) => row(i, [
    {toDisplay: [file.name, file.url], cellMaker: linkCell},
    {toDisplay: [file, i], cellMaker: delCell}
]));

const filesTable = (files) => table("", filesHeading, filesEntries(files));


function validDate(arr) {

    if (arr.some(el => isNaN(el))) {
        return false;
    }

    if (arr.every(el => el === "")) {
        return true;
    }

    const [year, month, dayFrom, dayTo] = arr.map(n => parseInt(n, 10));

    const dayInterval = Boolean(dayFrom && dayTo);
    const yearValid = year >= 2018;
    const monthValid = month >= 1 && month <= 12;
    const dayFromValid = dayFrom >= 1 && dayFrom <= 31;
    const dayToValid = dayTo >= 1 && dayTo <= 31;

    if (dayInterval && dayTo <= dayFrom) {
        return false;
    }

    if ((yearValid && monthValid && dayFromValid && dayToValid) ||
        (yearValid && monthValid && dayFromValid && !dayTo) ||
        (yearValid && monthValid && !dayFrom && !dayTo) ||
        (yearValid && !month && !dayFrom && !dayTo)) {
        return true;
    }

    return false;
}


export class EventEditor extends React.Component {

	constructor(props) {
		super(props);
        this.fileInput = React.createRef();

		this.state = {
            newEvent: true,
            saveButtonActive: true,
            uploadButtonActive: false,

            id: "",
			name: "",
			date: ["", "", "", ""],
			location: "",
			descEditable: "",
			descHTML: "",
			bg: "",
			infos: [],
			files: [],
			created: [],
			lastUpdated: [],
			newFile: ["", ""]

		}


		this.setFuncMaker = this.setFuncMaker.bind(this);
		this.fillState = this.fillState.bind(this);
        this.readFilesFromDB = this.readFilesFromDB.bind(this);
		this.setDate = this.setDate.bind(this);
		this.setEditorContent = this.setEditorContent.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
        this.updateNewFile = this.updateNewFile.bind(this);
        this.updateNewFileName = this.updateNewFileName.bind(this);
        this.clearFileUpload = this.clearFileUpload.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


	}


	fillState() {
        const receivedEvent = this.props.event;
		for (const key of Object.keys(this.state)) {
			if (!receivedEvent[key]) {
				continue;
			}

			this.setState({[key]: receivedEvent[key]});
		
		}

        const newEvent = !receivedEvent.id;
        this.setState({newEvent});

        if (!newEvent) {
            this.readFilesFromDB();
        }

	}

    readFilesFromDB() {
        const eventID = this.state.id || this.props.event.id;
        postToDB("get_files_from_db.php", {eventID})
            .then(recFiles => {
                this.setState({files: recFiles});
            })
            .catch(e =>  {
                console.error(e);
            });
    }

    setFuncMaker(key) {
    	return (e) => {
    		this.setState({[key]: e.target.value});
    	}
	}

    setDate(e, i) {
    	const newDate = [...this.state.date];
    	newDate[i] = e.target.value;
    	this.setState({date: newDate});
    }

    setEditorContent(editable, HTML) {
    	this.setState({descEditable: editable, descHTML: HTML});
    }

    

    updateNewFileName(e) {
        const newFile = [...this.state.newFile];
        newFile[0] = e.target.value;
        const uploadButtonActive = Boolean(newFile[1] && !this.state.newEvent);
        this.setState({newFile, uploadButtonActive});
    }

    updateNewFile(e) {
        const newFile = [...this.state.newFile];
        newFile[1] = e.target.files[0];
        const uploadButtonActive = Boolean(newFile[0] && !this.state.newEvent);
        this.setState({newFile, uploadButtonActive});

    }

    uploadFile() {
        // check: fileName exists, event exists
        if (!this.state.uploadButtonActive) {
            if (this.state.newEvent) {
                window.alert("Fájlok feltöltése előtt el kell menteni az eseményt.");
            }

            return;
        }

        this.setState({uploadButtonActive: false});

    	//upload file to server: 
        const [fileName, file] = this.state.newFile;
        const sendObj = {file, token: this.props.token};
        postFileToServer("upload_file_to_server.php", sendObj)
        .then(outcome => {
            if (outcome.success) {
                //write file data to db:
                const fileToDB = {
                    token: this.props.token,
                    eventID: this.state.id,
                    name: fileName,
                    url: devURL + "/files/public/events/" + outcome.uniqueName
                };
                return postToDB("write_file_to_db.php", fileToDB);
            }   
             
        })
        .then(outcome => {
            this.setState({uploadButtonActive: true});

            if (outcome.success) {
                this.readFilesFromDB();
                this.clearFileUpload();
            }

        })
        .catch(e =>  {
            console.error(e);
        });
    }

    clearFileUpload() {
        const newFile = ["", ""];
        this.fileInput.current.value = "";
        this.setState({
            newFile,
            uploadButtonActive: false,
        });
    }

    deleteFile(e, i) {
        const fileToDel = this.state.files[i];
        if (window.confirm(`Biztosan törölni szeretné a(z) ${fileToDel.name} nevű fájlt?`)) {
            //delete from server
            postToDB("delete_files_from_server.php", {urls: [fileToDel.url], token: this.props.token})
            .then((outcome) => {
                if (outcome.success) {
                    //delete from DB
                    postToDB("delete_files_from_db.php", {ids: [fileToDel.id], token: this.props.token})
                    .then(() => {
                        this.readFilesFromDB();
                    })
                }
            })
            .catch(e => {
                console.error(e);
            });
        } 
    }
    

    handleSubmit(e) {
    	e.preventDefault();
        if (!this.state.saveButtonActive) {
            return;
        }

        if (!validDate(this.state.date)) {
            window.alert("Helytelen dátum.");
            return;
        }

        this.setState({saveButtonActive: false});

    	const now = new Date();
        const lastUpdated = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()];
        const createdDate = this.state.created.length > 0 ? this.state.created : lastUpdated;

    	//write to DB
        //input validation here!!
    	const {descEditable, descHTML, name, date, location, id} = this.state;
        const sendObj = {
                id,
                descEditable, 
                descHTML, 
                name,
                location,
                createdDate: JSON.stringify(createdDate),
                lastUpdated: JSON.stringify(lastUpdated),
                date: JSON.stringify(date),
                token: this.props.token,
        };



        postToDB("write_event_to_db.php", sendObj)
            .then(outcome => {
                window.alert(outcome.message);
                this.setState({saveButtonActive: true});

                if (outcome.success) {
                    const [fileName, file] = this.state.newFile;
                    const uploadButtonActive = Boolean(fileName && file);
                    this.setState({
                        lastUpdated,
                        uploadButtonActive,
                        created: createdDate,
                        newEvent: false, 
                        id: outcome.id,
                    });
                    this.props.reloadEvents();
                }    
             
            })
            .catch(e =>  {
                console.error(e);
            });
        

    }


    componentDidMount() {
		this.fillState();

	}


    render() {
        return (
        	<div className={styles.container}>
        		<div className={styles.headerContainer}>
        			{this.state.newEvent ? 
        				<p>Új esemény</p> :
        				<div className={styles.recEventHeaderContainer}>
	        		    	<p className={styles.created}>Létrehozva: {formatCreatedDisplayDate(this.state.created)}</p>
	        		    	<p>Utoljára módosítva: {formatCreatedDisplayDate(this.state.lastUpdated)}</p>
        		 		</div>
        		 	}
        		</div>
        		<form className={styles.form} onSubmit={this.handleSubmit} >
        			<div className={styles.dataRow}><span className={styles.titleText}>*Név:</span> 
        				<input className={styles.inputText} type="text" onChange={this.setFuncMaker("name")} value={this.state.name} required={true}/>
        			</div>
        			<div className={styles.dataRow}><span className={styles.titleText}>Időpont:</span>
	        			<div className={styles.dateInputBox}>
	        				<input className={styles.inputText.concat(" ", styles.dateInput)} type="text" placeholder={"ÉÉÉÉ"}  onChange={(e) => this.setDate(e, 0)} value={this.state.date[0]} maxLength="4" size="4"/>
	        				<input className={styles.inputText.concat(" ", styles.dateInput)} type="text" placeholder={"HH"} onChange={(e) => this.setDate(e, 1)} value={this.state.date[1]} maxLength="2" size="2"/>
	        				<input className={styles.inputText} type="text" placeholder={"NN"} onChange={(e) => this.setDate(e, 2)} value={this.state.date[2]} maxLength="2" size="2"/>
                            <p className={styles.hyphen}>–</p>
                            <input className={styles.inputText} type="text" placeholder={"NN"} onChange={(e) => this.setDate(e, 3)} value={this.state.date[3]} maxLength="2" size="2"/>
	        			</div>
	        		</div>
        			<div className={styles.dataRow}><span className={styles.titleText}>Helyszín:</span>
        				<input className={styles.inputText} type="text" onChange={this.setFuncMaker("location")} value={this.state.location}/>
        			</div>
        			<div><span className={styles.titleText}>Leírás:</span>
        				<div className={styles.postEditorBox}>
        					<DraftJS token={this.props.token} newEvent={this.state.newEvent} getEditorContent={this.setEditorContent} recEditorContent={this.state.descEditable}/>
        				</div>
        			</div>
	  				<input className={styles.submit.concat(" ", this.state.saveButtonActive ? styles.active : styles.disabled)} type="submit" value="Mentés"/>
				</form>

                <p className={tsTabTitle}>Fájlok</p>
                {this.state.files.length > 0 && <div className={styles.recFilesContainer}>
                    {filesTable(this.state.files.map(f => {
                                                return {...f, 
                                                deleteFunc: this.deleteFile};
                                        }))
                    }
                </div>}

				<div className={styles.newFileContainer} >
                    <p className={styles.newFileTitle}>Új fájl feltöltése</p>
                    <div className={styles.uploadRow}>
					   <input className={styles.inputText.concat(" ", styles.fileNameInput)} placeholder="Fájlnév" type="text" onChange={this.updateNewFileName} value={this.state.newFile[0]}/>
					   <input className={styles.uploadButton} type="file" onChange={this.updateNewFile} ref={this.fileInput}/>
                    </div>
                    <div className={styles.submit.concat(" ", this.state.uploadButtonActive ? styles.active : styles.disabled)} onClick={this.uploadFile}>Feltöltés</div>
				</div>

        	</div>
            
  

            
        );
    }
}

EventEditor.propTypes = {
   
    token: PropTypes.string.isRequired,
    reloadEvents: PropTypes.func.isRequired,
    toggleEventEditor: PropTypes.func.isRequired,

    
};
