import {devURL} from '../../../../config/pages';

export async function postToDB(phpFileName, sendObj={}) {
	try {
	    let resp = await fetch(devURL + '/php/' + phpFileName,
	        {
	            method: 'POST',
	            headers: {},
	            body: JSON.stringify(sendObj)
	 
	        }
	    );

	    let respJson = await resp.json();
	    return respJson;

	} catch (e) {
		console.error(e);
	}

}