import {devURL} from '../../../../config/pages';


export async function postFileToServer(phpFileName, sendObj) {
	const formData  = new FormData();
	for (const [k, v] of Object.entries(sendObj)) {
		formData.append(k, v);
	}


	try {
	    let resp = await fetch(devURL + '/php/' + phpFileName,
	        {
	            method: 'POST',
	            body: formData
	 
	        }
	    );

	    let respJson = await resp.json();
	    return respJson;

	} catch (e) {
		console.error(e);
	}

}

