import React from "react";
import PropTypes from "prop-types";
import {ElnoksegSubPage} from './elnoksegSubPage';
import {EventEditor} from './eventEditor';
import {titleRow, row, iconsCell, table, onClickCell} from './table';
import {lastUpdatedFirst, formatCreatedDisplayDate} from '../../../common/contentAux';
import {getFromDB} from './dbCalls/getFromDB';
import {postToDB} from './dbCalls/postToDB';
import * as styles from './elnoksegPosts.module.scss';


const eventsHeading = titleRow(["Név", "Létrehozva", "Utoljára módosítva", ""]);
const eventsEntries = (events) => events.map((event, i) => row(i, [
    {toDisplay: [event.name, (e) => event.editFunc(e, i)], cellMaker: onClickCell},
    {toDisplay: formatCreatedDisplayDate(event.created)},
    {toDisplay: formatCreatedDisplayDate(event.lastUpdated)},
    {toDisplay: [event, i], cellMaker: iconsCell}
]));

const eventsTable = (events) => table("Események", eventsHeading, eventsEntries(events));

export class ElnoksegPosts extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            eventEditorOpen: false,
            events: []
      
        };   

        this.toggleEventEditor = this.toggleEventEditor.bind(this);
        this.openEventEditor = this.openEventEditor.bind(this);
        this.closeEventEditor = this.closeEventEditor.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.readEventsFromDB = this.readEventsFromDB.bind(this);

        
    }  

    openEventEditor(e, i) {
        const eventEditorOpen = true;
        const openEvent = !isNaN(i) ? this.state.events[i] : {};
        this.setState({eventEditorOpen, openEvent});
    }

    closeEventEditor() {
        const eventEditorOpen = false;
        this.setState({eventEditorOpen});

    }

    toggleEventEditor(e, i) {
        if (this.state.eventEditorOpen) {
            this.closeEventEditor();
        } else {
            this.openEventEditor(e, i);
        }
    }

    readEventsFromDB() {
        getFromDB("get_events_from_db.php")
            .then(recEvents => {
                recEvents.forEach(ev => {
                    try {
                        ev.date = JSON.parse(ev.date);
                        ev.created = JSON.parse(ev.created);
                        ev.lastUpdated = JSON.parse(ev.lastUpdated);
                    } catch(e) {
                        console.error(e);
                    }
                });
                recEvents.sort(lastUpdatedFirst);
                this.setState({events: recEvents});
            })
            .catch(e =>  {
                console.error(e);
            });
    }

    deleteEvent(e, i) {
        const eventToDel = this.state.events[i];
        if (window.confirm(`Biztosan törölni szeretné a(z) ${eventToDel.name} nevű eseményt?`)) {
            //get linked file urls from db
            postToDB("get_file_urls_for_event.php", {eventID: eventToDel.id})
            .then(urls => {
                //delete files from server
                return postToDB("delete_files_from_server.php", {urls, token: this.props.token});
            })
            .then(outcome => {
                if (outcome.success) {
                    //delete from DB
                    return postToDB("delete_event_from_db.php", {id: eventToDel.id, token: this.props.token});
                }
            })
            .then(() => {
                this.readEventsFromDB();
            })
            .catch(e => {
                console.error(e);
            });
        } 

    }

    componentDidMount() {
        this.readEventsFromDB();
    }


    render() {
    
        return (
            <ElnoksegSubPage displayGoBack={!this.state.eventEditorOpen}>
                <div className={styles.container}>
                    <div className={styles.newEventButton} onClick={this.toggleEventEditor}>
                        {this.state.eventEditorOpen ? "Vissza" : "Új esemény"}
                    </div>

                    {this.state.eventEditorOpen && <div className={styles.editorBox}>
                        <EventEditor token={this.props.token} event={this.state.openEvent} reloadEvents={this.readEventsFromDB} toggleEventEditor={this.toggleEventEditor}/>
                    </div>}

                    {!this.state.eventEditorOpen && <div className={styles.recEventContainer}>
                        {eventsTable(this.state.events.map(ev => {
                                                        return {...ev, 
                                                        editFunc: this.openEventEditor, 
                                                        deleteFunc: this.deleteEvent};
                                                    }))}
                    </div>}

                </div>
            </ElnoksegSubPage>
        );
    }
}

ElnoksegPosts.propTypes = {
   
    token: PropTypes.string.isRequired,


    
};

     
