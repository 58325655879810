import React from "react";
import {CloseIcon} from '../../img/closeIcon'
import {EditIcon} from '../../img/editIcon'
import {purple} from '../../../common/contentAux';
import * as ts from './table.module.scss';


const titleCell = (text, i, colWidth) => <p key={i} className={ts.cell.concat(" ", ts.titleText)} style={{"width": colWidth}}>{text}</p>;
export const titleRow = (cells) => {
    const colWidth = `${Math.round(100 / cells.length)}%`;
    return <div className={ts.row.concat(" ", ts.titleRow)}>
        {cells.map((text, i) => titleCell(text, i, colWidth))}
    </div>;
};

const cell = (text, i, colWidth) => <p key={i} className={ts.cell} style={{"width": colWidth}}>{text}</p>;

export const linkCell = (args, j, colWidth) => {
    const [name, url] = args;    
    return <a key={j} className={ts.cell} style={{"width": colWidth}} href={url} target="_blank" rel="noopener noreferrer">
        <p className={ts.linkCell}>{name}</p>
    </a>;
};

export const onClickCell = (args, j, colWidth) => {
    const [name, func] = args;    
    return <div key={j} className={ts.cell} style={{"width": colWidth}} onClick={func}>
        <p className={ts.linkCell}>{name}</p>
    </div>;
};

export const row = (i, cells) => {
    const colWidth = `${Math.round(100 / cells.length)}%`;
    const backgroundColor = i % 2 === 0 ? "white" : "#e3e1e8";
    return <div key={i} className={ts.row} style={{backgroundColor}}>
        {cells.map((content, j) => {
            const cellMaker = content.cellMaker || cell;
            return cellMaker(content.toDisplay, j, colWidth);
        })}
    </div>;
};

const editIcon = (entry, i) => <EditIcon className={ts.icon} iconFill={purple} onClick={(e) => entry.editFunc(e, i)}/>;
const delIcon = (entry, i) => <CloseIcon className={ts.icon} fillOpacity={100} iconFill={purple} onClick={(e) => entry.deleteFunc(e, i)}/>;


export const iconsCell = (args, j, colWidth) => {
    const [entry, i] = args;
    return <div key={j} className={ts.cell.concat(" ", ts.iconsContainer)} style={{"width": colWidth}}>
        {editIcon(entry, i)}
        {delIcon(entry, i)}        
    </div>;
};

export const delCell = (args, j, colWidth) => {
    const [entry, i] = args;
    return <div key={j} className={ts.cell.concat(" ", ts.iconsContainer)} style={{"width": colWidth}}>
        {delIcon(entry, i)}        
    </div>;
};

const tableTitle = (text) => <p className={ts.tableTitle}>{text}</p>;


export const table = (title, heading, entries) => <div className={ts.tableContainer}>
    {title && tableTitle(title)}
    {heading}
    {entries}                        
</div>;

