import {devURL} from '../../../../config/pages';

export async function getFromDB(phpFileName) {
	try {
	    let resp = await fetch(devURL + '/php/' + phpFileName, {method: 'POST'});

	    let respJson = await resp.json();
	    return respJson;

	} catch (e) {
		console.error(e);
	}

}